import React, { Component } from 'react';
import {
    HashRouter,
    Route,
    Switch,
    Redirect,
    DefaultRouteRedirect
} from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import Loadable from 'react-loadable';
// import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-datepicker/dist/react-datepicker.css';

import './App.scss';

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';

import * as Sentry from '@sentry/browser';

import feathers from './feathers';
import auth from './auth';

import 'react-toastify/dist/ReactToastify.css';

Sentry.init({
    dsn: 'https://0e6e782b6ea84f41a1b21f1fc6772e3d@sentry.io/1391808'
});

registerLocale('es', es);
setDefaultLocale('es');

const loading = () => (
    <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = Loadable({
    loader: () => import('./containers/DefaultLayout'),
    loading
});

// Pages
const Login = Loadable({
    loader: () => import('./views/Pages/Login'),
    loading
});

const Register = Loadable({
    loader: () => import('./views/Pages/Register'),
    loading
});

const ResetPassword = Loadable({
    loader: () => import('./views/Pages/ResetPassword'),
    loading
});

const EmailPassword = Loadable({
    loader: () => import('./views/Pages/EmailPassword'),
    loading
});

const Page404 = Loadable({
    loader: () => import('./views/Pages/Page404'),
    loading
});

const Page500 = Loadable({
    loader: () => import('./views/Pages/Page500'),
    loading
});

class App extends Component {
    constructor(props) {
        super(props);
        var _this = this;
        this.state = {
            loading: true,
            loged: false
        };

        window.addEventListener('unhandledrejection', event => {
            if (
                event.reason.message === 'No auth token' ||
                event.reason.message === 'Authentication timed out' ||
                event.reason.message === 'jwt expired'
            ) {
                _this.setState({
                    loged: false,
                    loading: false
                });
            }
        });

        feathers.on('logout', message => {
            console.log('logout event', message);
            _this.setState({
                loged: false
            });
        });

        feathers.on('authenticated', async message => {
            let payload = await feathers.passport.verifyJWT(
                message.accessToken
            );
            let user = await feathers.service('users-sql').get(payload.userId);
            user.role = user.roleObject.RoleName;
            feathers.set('user', user);
            _this.setState({
                loged: true
            });
        });

        this._authenticate();
    }

    _authenticate = async () => {
        try {
            await auth.authenticate();
            this.setState({
                loading: false,
                loged: true
            });
        } catch (err) {
            this.setState({
                loading: false,
                loged: false
            });
        }
    };

    render() {
        return (
            <HashRouter>
                {this.state.loading ? (
                    <div>Cargando...</div>
                ) : (
                    <Switch>
                        <Route
                            exact
                            path="/register"
                            name="Register Page"
                            component={Register}
                        />
                        <Route
                            exact
                            path="/resetPassword/:id"
                            name="Reset Password"
                            component={ResetPassword}
                        />
                        <Route
                            exact
                            path="/emailPassword"
                            name="Email Password"
                            component={EmailPassword}
                        />
                        <Route
                            exact
                            path="/404"
                            name="Page 404"
                            component={Page404}
                        />
                        <Route
                            exact
                            path="/500"
                            name="Page 500"
                            component={Page500}
                        />
                        {this.state.loged ? (
                            <Route
                                path="/"
                                name="Home"
                                component={DefaultLayout}
                            />
                        ) : (
                            <Switch>
                                <Route
                                    exact
                                    path="/"
                                    name="Login Page"
                                    component={Login}
                                />
                                <Redirect to="/" />
                            </Switch>
                        )}
                    </Switch>
                )}
            </HashRouter>
        );
    }
}

export default App;
